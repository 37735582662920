import queryString from "query-string"
import { getEnums } from "./localstorage"
// import { iconWarning } from "./icons"
import dayjs from "dayjs"
import { Image } from "antd"
import confirmLogoutImage from "assets/images/confirmLogoutImage.png"
import { getBaseDataLocal } from "./localstorage"
import { currency } from "components/Dropdown/DropdownCurrency/listOfCurrency"
import { countrys } from "components/Dropdown/DropdownCountry/Country"
import { myFonts, themeColors } from "./theme"
import { t } from "i18next"
import { parsePhoneNumber } from "awesome-phonenumber"

export function getTimeFromLocale(date, showTime = true) {
  if (!date) {
    return "N/A"
  }

  let options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  }
  if (!showTime) {
    delete options.hour
    delete options.minute
  }

  const { settings } = getBaseDataLocal()
  const settingApp = settings?.find((v) => v.key === "application-setting")
  if (settingApp && settingApp.value) {
    let code = settingApp.value.number_format || "JP"
    return new Date(date).toLocaleDateString(
      currency[code] ? currency[code].locale : currency["JP"].locale,
      options
    )
  }
  return new Date(date).toLocaleDateString(currency["JP"].locale, options)
}

export function formatPhone(code, phone) {
  let stringPhone = code + phone
  const { settings } = getBaseDataLocal()
  const settingApp = settings?.find((v) => v.key === "application-setting")
  if (settingApp && settingApp.value) {
    let newPhone = parsePhoneNumber(stringPhone)
    return newPhone?.number?.international
  }
  return stringPhone
}

export function convertPermission(permission) {
  const list = Object.keys(permission)
  let objPermission = {}
  for (let i = 0; i < list.length; i++) {
    let __path = list[i]?.split(".")[0]
    let values = permission[list[i]]
    if (!objPermission[__path]) objPermission[__path] = {}
    for (let i = 0; i < values.length; i++) {
      if (values[i].method === "*") {
        objPermission[__path] = {
          DELETE: true,
          POST: true,
          PUT: true,
          GET: true,
          CLOSE: true,
          DETAIL: true,
          ATRACT: true,
          APPROVAL: true,
          VIEW: true,
          IMPORT: true,
          EXPORT: true
        }
        break
      } else {
        objPermission[__path][values[i].method] = true
      }
    }
  }
  return objPermission
}

export function downloadObjectAsJson(exportObj, exportName) {
  var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj))
  var downloadAnchorNode = document.createElement("a")
  downloadAnchorNode.setAttribute("href", dataStr)
  downloadAnchorNode.setAttribute("download", exportName + ".json")
  document.body.appendChild(downloadAnchorNode)
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}
export function downloadBlob(filename, content) {
  var blob = new Blob([content], { type: "text/csv;charset=utf-8;" })
  var url = URL.createObjectURL(blob)
  var pom = document.createElement("a")
  pom.href = url
  let timeName = "-" + dayjs().format("YYYY/MM/DD HH MM ss") + ".csv"
  pom.setAttribute("download", filename + timeName)
  pom.click()
}
export function convertDurationToClock(n) {
  let h = Math.floor(n / 3600)
  let m = n % 3600
  let _m = Math.floor(m / 60)
  let s = m % 60
  if (h === 0) {
    h = ""
  } else {
    h = h + ":"
  }
  if (_m < 10) _m = "0" + _m
  if (s < 10) s = "0" + s
  return h + _m + ":" + s
}

export function convertTranslates(array) {
  let resource = {}
  for (let i = 0; i < array.length; i++) {
    resource[array[i].file_element.element_id] = array[i].translation_content
  }
  return resource
}
export function convertModal({
  text,
  description,
  image = confirmLogoutImage,
  className,
  width = 230,
  height = 190
}) {
  return {
    className: className || "modal_confirm",
    icon: null,
    centered: true,
    title: null,
    cancelText: "Cancel",
    content: (
      <div>
        <div className="flex justify-center my-5">
          <Image width={width} height={height} preview={false} src={image} />
        </div>
        <div className="text-center font-bold text-lg text-dark-500 mb-2">{text}</div>
        <div className="text-center text-dark-400 text-sm px-4">{description}</div>
      </div>
    ),
    okText: "Confirm"
  }
}
export default function convertTransalte() {
  let translates = {
    en: "English"
  }
  const translatesStore = localStorage.getItem("language")
  if (translatesStore) translates = JSON.parse(translatesStore)
  let rows = Object.keys(translates).map((i) => {
    return {
      label: translates[i],
      value: i
    }
  })
  return rows
}

export function converDataTree(_list, filter) {
  let list = [..._list]
  function loop(__list) {
    for (let i = 0; i < __list.length; i++) {
      __list[i].value = __list[i].id
      __list[i].title = __list[i].name

      if (__list[i].children && __list[i].children.length > 0) {
        if (filter) {
          __list[i].selectable = true
        } else {
          __list[i].selectable = false
        }
        loop(__list[i].children)
      }
    }
  }
  loop(list)
  return list
}

export const paramsUrl = {
  get: () => {
    return queryString.parse(window.location.search)
  },
  set: (params) => {
    const currentUrlParams = queryString.stringify(params, {
      skipNull: true
    })
    window.history.replaceState(
      {},
      null,
      `${window.location.pathname}?${currentUrlParams.toString()}`
    )
  }
}

export function getSearchParams() {
  return queryString.parse(window.location.search)
}

export function getRole() {
  const role = localStorage.getItem("role")
  return role || false
}

export function getToken() {
  return localStorage.getItem("accessToken")
}

export function convertEnumToDropdown(key) {
  const enums = getEnums()
  return enums[key]
}

export function getServiceCode(objectCode) {
  const application = JSON.parse(localStorage.getItem("application"))
  return `${application?.organization?.code}-${objectCode}`
}

export function hexToRgba(hex, alpha) {
  if (!hex?.match(/^#([0-9a-f]{3}|[0-9a-f]{6})$/i)) {
    return null
  }

  hex = hex.replace(/^#/, "")
  let r, g, b
  if (hex.length === 3) {
    r = parseInt(hex.charAt(0) + hex.charAt(0), 16)
    g = parseInt(hex.charAt(1) + hex.charAt(1), 16)
    b = parseInt(hex.charAt(2) + hex.charAt(2), 16)
  } else {
    r = parseInt(hex.substring(0, 2), 16)
    g = parseInt(hex.substring(2, 4), 16)
    b = parseInt(hex.substring(4, 6), 16)
  }
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export function formatNumber(number) {
  try {
    if (number === 0) return 0
    if (!number) return "N/A"
    let numbers = number.toString().replaceAll(",", ".").split(".")
    let newNumber = parseInt(numbers[0])
    if (newNumber) {
      const { settings } = getBaseDataLocal()
      const settingApp = settings?.find((v) => v.key === "application-setting")
      let code = settingApp.value.number_format || "JP"
      return new Intl.NumberFormat(currency[code].locale).format(number)
    }
    return 0
  } catch (error) {
    console.log(error)
  }
}

export function formatCurrency(number, code) {
  if (parseFloat(number) === 0 && !code) return "Free"
  if (number && code) {
    let obj = currency[code]
    if (!obj) return "N/A"
    const newNumber = new Intl.NumberFormat(obj.locale, {
      style: "currency",
      currency: obj.currency
    }).format(number)
    return `${newNumber} ${obj.currency}`
  }
  return "N/A"
}

export function payType(v) {
  if (v === 0) return "Bank transfer"
  if (v === 1) return "Cash"
  if (v === 3) return "Stripe"
  if (v === 4) return "Pay JP"
}

export function renderTime(timeString) {
  const time = dayjs(timeString)
  const now = dayjs()
  const diff = now.diff(time, "seconds")
  let timeAgoString = ""

  if (diff < 60) {
    timeAgoString = `${parseInt(diff)}${t("s")}`
  } else if (diff < 3600) {
    timeAgoString = `${parseInt(diff / 60)}${t("m")}`
  } else if (diff < 86400) {
    timeAgoString = `${parseInt(diff / 3600)}${t("h")}`
  } else if (diff < 2678400) {
    timeAgoString = `${parseInt(diff / 86400)}${t("d")}`
  } else {
    timeAgoString = getDateSetting(timeString)
  }
  // else if (diff < 32140800) {
  //   timeAgoString = `${parseInt(diff / 2678400)} months ago`
  // } else {
  //   timeAgoString = `${parseInt(diff / 32140800)} years ago`
  // }
  return timeAgoString
}

export function renderTimeLastActivity(timeString) {
  const time = dayjs(timeString)
  const now = dayjs()
  const diff = now.diff(time, "seconds")
  let timeAgoString = ""

  if (diff < 60) {
    timeAgoString = `${parseInt(diff)}${t("s")} ${t("ago")}`
  } else if (diff < 3600) {
    timeAgoString = `${parseInt(diff / 60)}${t("m")} ${t("ago")}`
  } else if (diff < 86400) {
    timeAgoString = `${parseInt(diff / 3600)}${t("h")} ${t("ago")}`
  } else if (diff < 2678400) {
    timeAgoString = `${parseInt(diff / 86400)}${t("d")} ${t("ago")}`
  } else {
    timeAgoString = getDateSetting(timeString)
  }
  return timeAgoString
}

export function getFormatSpecificDate() {
  const { settings } = getBaseDataLocal()
  const settingApp = settings?.find((v) => v.key === "application-setting")
  let _format = "YYYY/MM/DD"
  if (settingApp && settingApp.value) {
    let code = settingApp.value.number_format || "JP"
    if (currency[code]) {
      const _arr = currency[code].formatDateTime.split(" ")
      const longest = _arr.reduce((a, b) => (a.length >= b.length ? a : b))
      _format = longest.toUpperCase()
    }
  }
  return _format
}

export function getFormatSpecificTime() {
  const { settings } = getBaseDataLocal()
  const data = settings?.find((v) => v.key === "application-setting")
  let _format = "HH:mm"
  if (data) {
    _format = data?.value?.time_format || "HH:mm"
  }
  return _format
}

export function getLocale() {
  const { settings } = getBaseDataLocal()
  const data = settings?.find((v) => v.key === "application-setting")
  let language = data?.value?.language || "jp"
  return language
}

export function getFormatDateSetting() {
  const { settings } = getBaseDataLocal()
  const data = settings?.find((v) => v.key === "application-setting")
  let _format = "YYYY/MM/DD HH:mm"
  if (data) {
    _format = `${getFormatSpecificDate()} ${getFormatSpecificTime()}`
  }
  return _format
}

export function getDateSetting(date) {
  const { settings } = getBaseDataLocal()
  const data = settings?.find((v) => v.key === "application-setting")
  let _format = "YYYY/MM/DD HH:mm"
  if (data) {
    _format = getFormatDateSetting() || "YYYY/MM/DD HH:mm"
  }
  if (!date) return "N/A"
  return dayjs(date).format(_format)
}

export function getCurrency(code) {
  let obj = currency[code]
  if (obj) return currency[code].currency
  return ""
}

export function getFirstElement(body) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(body, "text/html")
  const firstElement = doc.body.firstElementChild

  // Nếu body không có element nào thì firstElement sẽ là null
  const firstElementHtml = firstElement ? firstElement.outerHTML : ""
  return firstElementHtml
}

export const listBillStatus = [
  {
    label: "Pending",
    value: 0
  },
  {
    label: "Paid",
    value: 1
  },
  {
    label: "Overdue",
    value: 2
  },
  {
    label: "Canceled",
    value: 3
  },
  {
    label: "Refunded",
    value: 4
  }
]

export const listTransactionStatus = [
  {
    label: "Pending",
    value: 0
  },
  {
    label: "Successful",
    value: 1
  },
  {
    label: "Failed",
    value: 2
  },
  {
    label: "Refunded",
    value: 3
  },
  {
    label: "Canceled",
    value: 4
  }
]

export function getActiveAction(action) {
  const { permission } = getBaseDataLocal()

  return !!permission.find((i) => i === action)
}

export function getCurrentTimezone() {
  const date = new Date()
  const offset = -date.getTimezoneOffset() / 60 // đổi phút sang giờ và đảo dấu
  const offsetSign = offset >= 0 ? "+" : "-"
  const formattedOffset = `${offsetSign}${Math.abs(offset)}`
  return formattedOffset
}

export function getCountryName(code) {
  let contry = countrys.find((i) => i.value === code)
  return contry ? contry.label : "N/A"
}

export function getLimitBilling(v, t) {
  return v === -1 ? t("Unlimited") : formatNumber(v)
}

export function getThemeConfigs(configs) {
  const themeSetting = configs.find((i) => i.key === "theme-setting")
  const { color_palette_type, typography, logo, name, favicon } = themeSetting?.value || {}
  const themeKey = color_palette_type || 1
  const itemFont = myFonts.find((i) => i.value === typography)

  return {
    color: themeColors.find((i) => i.value === themeKey)?.color,
    fontFamily: itemFont.label,
    themeKey,
    favicon,
    logo,
    name
  }
}

export function applyTheme(settingTheme) {
  if (settingTheme?.fontFamily) {
    const itemFont = myFonts.find((i) => i.label === settingTheme.fontFamily) || myFonts[0]
    var link = document.createElement("link")
    link.setAttribute("rel", "stylesheet")
    link.setAttribute("type", "text/css")
    link.setAttribute("href", itemFont.href)
    document.head.appendChild(link)
    document.body.style.fontFamily = settingTheme.fontFamily
    document.documentElement.setAttribute("data-theme", `theme${settingTheme.themeKey}`)
    const linkElement = document.querySelector("link[rel=icon]")
    linkElement.href = settingTheme.favicon
  }
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getEmailSetting() {
  try {
    const { settings } = getBaseDataLocal()
    const data = settings?.find((v) => v.key === "overview")
    return data.value.email
  } catch (error) {
    console.log(error)
    return "Email string N/A"
  }
}
